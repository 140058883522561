<template>
  <v-dialog v-model="modalUpdatePayrollBenefit" width="500" persistent @close="setModalUpdatePayrollBenefit(false)"
    transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-btn icon depressed @click="setModalUpdatePayrollBenefit(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="from-content">
        <label class="label-input ml-10">{{ payroll_benefit.name }}</label>
        <div class="from">
          <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber" :clearable="true"
            v-model="payroll_benefit.value" required />
          <v-btn class="btn-save-change" @click="saveChange">
            ບັນທຶກຂໍ້ມູນ
          </v-btn>
          <v-btn color="red" class="ml-4 btn-cancel lfont" @click="removeBenefit">
            ລືບຂໍ້ມູນ
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    payroll_benefit: {},
    payroll_emp_id: {},
    icon: {
      default: "mdi-close",
      type: String,
    },
  },
  data() {
    return {
      modalUpdatePayrollBenefit: false,
    };
  },

  methods: {
    setModalUpdatePayrollBenefit(isVisible) {
      this.modalUpdatePayrollBenefit = isVisible;
      this.$emit("change", isVisible);
    },

    removeBenefit() {
      const payroll_emp_id = this.payroll_emp_id;
      const payroll_benefit = this.payroll_benefit.id;
      this.$axios
        .delete(
          `company/delete/payroll/benefit/item/deatils/${payroll_benefit}/${payroll_emp_id}`
        )
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$emit("success");
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "danger",
                3000
              );
            }, 300);
          }
          this.setModalUpdatePayrollBenefit(false);
          this.$emit("success");
        }).catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },

    saveChange() {
      const payroll_emp_id = this.payroll_emp_id;
      const item = {
        id: this.payroll_benefit.id,
        value: this.payroll_benefit.value,
      };
      const payrollBenefits = [];
      payrollBenefits.push(item);
      this.$axios
        .put(`company/edit/payroll/benefits/${payroll_emp_id}`, {
          payrollBenefits: payrollBenefits,
        })
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$emit("success");
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.setModalUpdatePayrollBenefit(false);
          this.$emit("success");
        })
        .catch(() => { });
    },
  },
  watch: {
    visible(val) {
      this.modalUpdatePayrollBenefit = val;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-cancel {
  color: white !important;
  font-weight: 800 !important;
}

.from-content {
  width: 100%;
  height: 200px;
  overflow-y: hidden;

  .from {
    width: 100%;
    height: 160px;
    padding: 15px 40px;
  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4a4a4a;
}
</style>
