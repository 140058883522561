<template>
  <v-dialog v-model="modalDeduction" width="900" persistent @close="setModalDeduction(false)"
    transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-btn icon depressed @click="setModalDeduction(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="from-content">
        <v-form ref="form">
          <v-row>
            <v-col class="d-flex flex-row pa-0" cols="12" md="12" v-for="(item, index) in Benefits" :key="index">
              <v-col cols="6" md="6">
                <label class="label-input">ເງີນພິເສດ</label>
                <v-select outlined dense :items="listBenefits" v-model="item.benefitId" item-text="name" item-value="id"
                  required>
                </v-select>
              </v-col>
              <v-col cols="6" md="6">
                <label class="label-input">ຈຳນວນ</label>
                <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber" :clearable="true"
                  v-model="item.value" required />
              </v-col>
              <span>
                <div class="removeContact" v-show="Benefits.length > 1">
                  <i class="fas fa-minus-circle" @click="removeFieldDeduction(item)"></i>
                </div>
              </span>
            </v-col>
            <v-col cols="12">
              <div class="btn-addFieldContact" @click="addFieldDeduction()">
                <span class="mr-2"><i class="fal fa-plus"></i></span>ເພີ່ມຂໍ້ມູນ
              </div>
            </v-col>
            <v-col cols="12">
              <v-btn style="float: right" class="btn-save-change" @click="creteBenefits">
                ບັນທຶກຂໍ້ມູນ
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    payroll_emp_id: "",
    emp_id: "",
    icon: {
      default: "mdi-close",
      type: String,
    },
  },
  data() {
    return {
      modalDeduction: false,
      listBenefits: [],
      Benefits: [
        {
          benefitId: 1,
          value: "",
        },
      ],
    };
  },

  methods: {
    setModalDeduction(isVisible) {
      this.modalDeduction = isVisible;
      this.$emit("change", isVisible);
    },
    fetchBenefits() {
      this.$axios
        .get(`company/list/benefits`)
        .then((res) => {
          if (res) {
            this.listBenefits = res.data.benefits;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resetForm() {
      this.$refs.form.reset();
    },

    addFieldDeduction() {
      this.Benefits.push({
        benefitId: "",
        value: "",
      });
    },
    removeFieldDeduction(item) {
      let idx = this.Benefits.indexOf(item);
      this.Benefits.splice(idx, 1);
    },

    creteBenefits() {
      const item = {
        payrollBenefits: this.Benefits,
        emp_id: this.emp_id,
      };
      const payroll_emp_id = this.payroll_emp_id;
      this.setModalDeduction(false);
      this.$axios
        .post(
          `company/add/payroll/benefit/item/deatils/${payroll_emp_id}`,
          item
        )
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.resetForm();
            this.$emit("success");
          }
        });
    },

    saveChange() { },
  },
  watch: {
    visible(val) {
      this.modalDeduction = val;
    },
  },
  created() {
    this.fetchBenefits();
  },
};
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  min-height: 500px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 60px;

  .from {
    width: 100%;
    height: auto;
    padding: 20px 40px;
  }
}

.removeContact {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  i {
    font-size: 1.4rem;
    margin-top: 8px;
    color: #ff3d6a;
    cursor: pointer;
  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4a4a4a;
}

.btn-addFieldContact {
  width: 200px;
  height: 41px;
  background-color: $main-btn-color;
  margin-bottom: 30px;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: $font-family;
}
</style>
