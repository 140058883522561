<template>
  <div>
    <v-dialog v-model="modalUpdateDeduction" width="1200" persistent @close="setModalUpdateDeduction(false)"
      transition="scroll-x-transition">
      <v-card class="main-card-delete">
        <v-card-actions class="card-action-delete">
          <v-btn icon depressed @click="setModalUpdateDeduction(false)">
            <v-icon class="icon-style">{{ icon }}</v-icon>
          </v-btn>
        </v-card-actions>
        <div class="from-content">
          <label class="label-input ml-10">{{ deductionItem.name }}</label>


          <div class="from">

            <table style="width: 100%;">
              <tbody class="deduction-table">
                <tr>
                  <td width="250">
                    <v-radio-group v-model="deductionItem.option"
                      style="padding: 0px; margin: 0px; font-size: 12px !important;" class="option-group lfont" row>
                      <v-radio :label="$t('payrollAddDeduction.money')" value="money"
                        @change="option = 'money'"></v-radio>
                      <v-radio :label="$t('payrollAddDeduction.percent')" value="percent"
                        @change="option = 'percent'"></v-radio>
                    </v-radio-group>
                    <div class="deduction-value">

                      <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber" :clearable="true"
                        v-model="deductionItem.percent" required v-if="deductionItem.option == 'percent'" />
                      <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber" :clearable="true"
                        v-model="deductionItem.money" required v-else />

                    </div>
                  </td>
                  <td>
                    <div v-if="deductionItem.option == 'percent'">
                      <label class="label-input">{{ $t("payrollAddDeduction.cut_out_deduction") }}</label>
                      <v-checkbox :label="$t('payrollAddDeduction.condition')" class="lfont" style="margin-bottom: -20px;"
                        v-model="deductionItem.cut_out_condition"></v-checkbox>
                      <v-btn x-small color="info" dark class="lfont" @click="add_cut_out_deduction()">
                        + {{ $t("payrollAddDeduction.deduction_title") }}
                      </v-btn>
                      <ul v-for="( cut_item, i ) in  deductionItem.cut_out_deduction " :key="i" style="padding: 0px;"
                        class="lfont">
                        <li style="display: flex; justify-content: space-between">
                          <div>{{ i + 1 }} {{ cut_item.name }}</div>
                          <v-btn icon color="pink" style="height: unset; width:unset"
                            @click="remove_cut_out_deduction(i)">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <label class="label-input">{{ $t("payrollAddDeduction.condition") }}</label>
                    <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber" :clearable="true"
                      v-model="deductionItem.condition" required class="deduction-item" />
                  </td>
                  <td>
                    <label class="label-input">{{ $t("payrollAddDeduction.employer") }}</label>
                    <v-checkbox v-model="deductionItem.employer" color="primary" hide-details
                      class="deduction-item lfont"></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex">
              <v-btn class="btn-save-change" @click="saveChange">
                {{ $t('payrollAddDeduction.btnSave') }}
              </v-btn>
              <v-btn color="red" class="ml-4 btn-cancel" @click="removeDeduction">
                {{ $t('payrollAddDeduction.btnDel') }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddCutDeduction" max-width="500px">
      <v-card class="lfont">
        <v-card-title>
          <span>ລາຍການຕັດເງີນ</span>
          <v-spacer></v-spacer>

        </v-card-title>
        <div class="pl-5 pr-5">
          <v-select outlined dense :items="listDeductionAdd" item-text="name" item-value="id" required
            class="deduction-item" v-model="addCutDeduction"></v-select>
        </div>
        <v-card-actions>
          <v-btn color="primary" text @click="set_cut_out_deduction">
            Add
          </v-btn>
          <v-btn color="red" text @click="dialogAddCutDeduction = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    deductionItem: {},
    payroll_emp_id: {},
    icon: {
      default: 'mdi-close',
      type: String
    },
  },
  data() {
    return {
      dialogAddCutDeduction: false,
      modalUpdateDeduction: false,
      listDeduction: [],
      listDeductionAdd: [],
      addCutDeduction: [],
      server_errors: {
        payrollDeductionItemDetails: {
          detail_id: "",
          option: "",
          value: "",
          percent: "",
          condition: "",
          money: "",
          cut_out_deduction: [],
        }
      }
    }
  },

  methods: {
    remove_cut_out_deduction(index) {
      this.deductionItem.cut_out_deduction.splice(index, 1);
    },
    set_cut_out_deduction() {
      this.dialogAddCutDeduction = false;
      if (this.deductionItem.cut_out_deduction == null) {
        this.deductionItem.cut_out_deduction = [];
      }
      let checked = this.deductionItem.cut_out_deduction.find(item => item.id === this.addCutDeduction);
      if (checked) return;
      let selected_deduction = this.listDeduction.find(item => item.id === this.addCutDeduction);
      this.$set(selected_deduction, "money", 0);
      this.deductionItem.cut_out_deduction.push(selected_deduction);
    },
    add_cut_out_deduction() {
      this.fetchDeductionsAdd();
      this.dialogAddCutDeduction = true;
    },
    choose_option() {
      this.option = this.deductionItem.option;
    },
    setModalUpdateDeduction(isVisible) {
      this.modalUpdateDeduction = isVisible;
      this.$emit('change', isVisible);
    },

    saveChange() {
      let cut_out_deduction = this.deductionItem.cut_out_deduction.map(item => item.id);
      this.$emit('save', true);
      const payroll_emp_id = this.payroll_emp_id;
      const item = {
        detail_id: this.deductionItem.id,
        option: this.deductionItem.option,
        value: this.deductionItem.value,
        money: this.deductionItem.money,
        percent: this.deductionItem.percent,
        condition: this.deductionItem.condition,
        employer: this.deductionItem.employer,
        cut_out_deduction: cut_out_deduction,
        cut_out_condition: this.deductionItem.cut_out_condition,
      }
      const payrollDeductionItemDetails = [];
      payrollDeductionItemDetails.push(item);
      this.$axios.put(`company/edit/payroll/deduction/item/deatils/${payroll_emp_id}`, {
        payrollDeductionItemDetails: payrollDeductionItemDetails
      }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$emit('success');
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.setModalUpdateDeduction(false)
        this.$emit("success");
      }).catch((error) => {
        this.$emit('save', false);
        if (error.response.status === 422) {
          let obj = error.response.data.errors;
          if (!obj) {
            obj = error.response.data;
            let res = "";
            for (let [key, value] of Object.entries(obj)) {
              res = value;
            }
            this.$notification.ShowErrors("top-right", "danger", 3000, res);
          } else {
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
              this.$notification.ShowErrors(
                "top-right",
                "danger",
                3000,
                (this.server_errors[key] = value[0])
              );
            }
          }
        }
      })
    },
    removeDeduction() {
      this.$emit('save', true);
      const payroll_emp_id = this.payroll_emp_id;
      const deduction_item_detail_id = this.deductionItem.id;
      this.$axios.delete(`company/delete/payroll/deduction/item/deatils/${deduction_item_detail_id}/${payroll_emp_id}`).then((res) => {
        if (res.status === 200) {
          this.setModalUpdateDeduction(false);
          this.$emit("success");
        }
      }).catch((error) => {
        this.$emit('save', false);
      })
    },
    fetchDeductions() {
      this.$axios.get(`company/list/payroll/deduction/items`).then((res) => {
        if (res.status === 200) {
          this.listDeduction = res.data.data;
        }
      });
    },

    fetchDeductionsAdd() {
      this.$axios.get(`company/list/payroll/deduction/items/add/${this.payroll_emp_id}`).then((res) => {
        if (res.status === 200) {
          this.listDeductionAdd = res.data.data;
        }
      });
    },

  },
  watch: {
    visible(val) {
      this.modalUpdateDeduction = val;
    },
  },
  created() {
    this.fetchDeductions();
  },
}
</script>

<style scoped lang="scss">
.deduction-table {
  tr {
    td {
      border: 1px solid rgb(210, 210, 210);
    }
  }
}

.deduction-value {
  display: flex;

  .cut-out-deduction {
    width: 180%;
    padding: 0px 5px;
  }
}

.deduction-item {
  margin-top: 20px;
}

table {
  tr {
    td {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.option-group {
  margin-top: 0px;
  display: flex;

}

.from-content {
  width: 100%;
  overflow-y: hidden;
  padding-bottom: 50px;

  .from {
    width: 100%;
    padding: 15px 40px;
  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4A4A4A;
}

.btn-button-delete {
  width: 100px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 1.4rem;
    cursor: pointer;
  }
}

.btn-cancel {
  font-family: $font-family;
  color: #FFFFFF !important;
  margin-top: 6px;
}
</style>
