<template>
  <v-dialog v-model="modalUpdatePayrollData" width="500" persistent @close="setmodalUpdatePayrollData(false)"
    transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-btn icon depressed @click="setmodalUpdatePayrollData(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="from-content">
        <label class="label-input ml-10" v-if="editData.title == 'absence'">{{
          $t("payrollItem.editData.absence")
        }}</label>
        <label class="label-input ml-10" v-if="editData.title == 'late'">{{
          $t("payrollItem.editData.late")
        }}</label>
        <label class="label-input ml-10" v-if="editData.title == 'leave_early'">{{ $t("payrollItem.editData.leave_early")
        }}</label>
        <div class="from">
          <vuetify-money dense :outlined="true" :clearable="true" v-bind:options="options2" v-model="editData.value"
            required />
          <v-btn class="btn-save-change" @click="saveChange">
            {{ $t("payrollItem.editData.btnSave") }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    editData: {},
    icon: {
      default: "mdi-close",
      type: String,
    },
  },
  data() {
    return {
      modalUpdatePayrollData: false,
      options2: {
        locale: "en-US",
        prefix: "",
        suffix: "",
        length: 9,
        precision: 2
      },
    };
  },

  methods: {
    setmodalUpdatePayrollData(isVisible) {
      this.modalUpdatePayrollData = isVisible;
      this.$emit("change", isVisible);
    },

    saveChange() {
      const emp_payroll_id = this.editData.emp_payroll_id;
      this.$axios
        .put(`company/edit/payroll/data/${emp_payroll_id}`, this.editData)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$emit("success");
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.setmodalUpdatePayrollData(false);
          this.$emit("success");
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  watch: {
    visible(val) {
      this.modalUpdatePayrollData = val;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-cancel {
  color: white !important;
  font-weight: 800 !important;
}

.from-content {
  width: 100%;
  height: 200px;
  overflow-y: hidden;

  .from {
    width: 100%;
    height: 160px;
    padding: 15px 40px;
  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4a4a4a;
}
</style>
