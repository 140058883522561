<template>
  <v-dialog v-model="modalUpdatePayrollData" width="500" persistent @close="setmodalUpdatePayrollData(false)"
    transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-btn icon depressed @click="setmodalUpdatePayrollData(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="from-content">

        <div class="from">
          <v-text-field dense label="ຊົ່ວໂມງ" outlined v-model="editTime.hours" type="number"></v-text-field>
          <v-text-field dense label="ນາທີ" outlined v-model="editTime.minutes" type="number"></v-text-field>

          <v-btn class="btn-save-change" @click="saveChange">
            {{ $t("payrollItem.editData.btnSave") }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
  
<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    editTime: {},
    icon: {
      default: "mdi-close",
      type: String,
    },
  },
  data() {
    return {
      modalUpdatePayrollData: false,
      options2: {
        locale: "en-US",
        prefix: "",
        suffix: "",
        length: 9,
        precision: 2
      },
    };
  },

  methods: {
    convertHours(minutes) {
      let hours = Math.floor(minutes / 60);
      return hours;
    },
    convertMinutes(minutes) {
      let remainingMinutes = minutes % 60;
      return remainingMinutes;
    },
    setmodalUpdatePayrollData(isVisible) {
      this.modalUpdatePayrollData = isVisible;
      this.$emit("change", isVisible);
    },

    saveChange() {
      const emp_payroll_id = this.editTime.emp_payroll_id;
      const data = {
        title: this.editTime.title,
        value: parseFloat(this.editTime.hours) + parseFloat(this.editTime.minutes / 60),
        emp_payroll_adjustment_id: this.editTime.emp_payroll_adjustment_id
      };
      this.$axios
        .put(`company/edit/payroll/data/${emp_payroll_id}`, data)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$emit("success");
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.setmodalUpdatePayrollData(false);
          this.$emit("success");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  watch: {
    visible(val) {
      this.modalUpdatePayrollData = val;
    },
  },
};
</script>
  
<style scoped lang="scss">
.btn-cancel {
  color: white !important;
  font-weight: 800 !important;
}

.from-content {
  width: 100%;
  height: 250px;
  overflow-y: hidden;

  .from {
    width: 100%;
    height: 160px;
    padding: 15px 40px;
  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4a4a4a;
}
</style>
  