<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog_add_emp" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">

        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5"><span class="lfont">ເພີ່ມລາຍຊື່ພະນັກງານ</span></span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="5">
                  <v-autocomplete filled dense :label="$t('payrollItem.department')" item-text="name" item-value="id"
                    outlined :items="deparments" hide-selected v-model="deparment">
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="5">
                  <v-autocomplete filled dense :label="$t('payrollItem.branch')" item-text="branch_name" item-value="id"
                    outlined :items="branchs" hide-selected v-model="branch">
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn class="btn-save-change mb-5 ml-4" @click="fetchAllEmployee">
                    <span><i class="fal fa-search"></i></span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <label class="label-input">
                    <span style="font-size: 1.4rem"><i class="fal fa-users-medical"></i></span>
                    {{ $t("payrollItem.selectEmp") }}
                  </label>
                  <v-select outlined item-text="name" item-value="id" multiple :items="allEmployee"
                    v-model="selectAllEmployee">
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="onSelectAllEmployee">
                        <v-list-item-action>
                          <v-icon :color="selectAllEmployee.length > 0 ? 'indigo darken-4' : ''
                            ">
                            {{ icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Select All </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col class="mb-10" cols="12" md="12" lg="12" v-if="selectAllEmployee.length > 0">
                  <v-btn class="btn-save-change" @click="addEmployeeInPayroll">
                    SaveChange
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeModal()">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- <Loading v-if="isLoading" /> -->
  </div>
</template>

<script>
import Loading from "@/components/Loading";
export default {
  components: {
    Loading,
  },
  props: {
    visible: {
      default: false,
    },
    payroll_id: "",
  },
  data() {
    return {
      dialog_add_emp: false,
      deparment: "",
      branch: "",
      branchs: [],
      deparments: [],
      isLoading: false,
      allEmployee: [],
      emp_id: "",
      selectAllEmployee: [],
      icon: "mdi-checkbox-blank-outline"
    };
  },
  watch: {
    visible(val) {
      this.dialog_add_emp = val;
    },
  },
  methods: {
    closeModal() {
      this.dialog_add_emp = false;
      this.$emit("change", false);
    },

    fetchAllEmployee() {
      let body = {
        department_id: this.deparment,
        branch_id: this.branch,
      };
      this.isLoading = true;
      this.$axios
        .get(`company/all-employee-name`, { params: body })
        .then((res) => {
          this.allEmployee = res.data.data;
          this.isLoading = false;
        })
        .catch(() => { });
    },
    selectAllEmployees() {
      return this.selectAllEmployee.length === this.allEmployee.length;
    },
    selectSomeEmployee() {
      return this.selectAllEmployee.length > 0 && !this.selectAllEmployees;
    },
    // icon() {
    //     alert('icon');
    //     if (this.selectAllEmployees) return "mdi-close-box";
    //     if (this.selectSomeEmployee) return "mdi-minus-box";
    //     return "mdi-checkbox-blank-outline";
    // },
    addEmployeeInPayroll() {
      const employee_id = [];
      this.selectAllEmployee.map((item) => {
        return employee_id.push(item.id || item);
      });
      this.isLoading = true;
      this.$axios
        .post(`company/add/payroll/employees/${this.payroll_id}`, {
          employees: employee_id,
        })
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("success");
            this.$emit("change", false);
          }
          this.selectAllEmployee = [];
          this.dialog_add_emp = false;
          this.isLoading = false;
          this.resetForm();
        }).catch((error) => {
          this.isLoading = false;
        });
    },
    resetForm() {
      this.$refs.form.reset();
    },
    fetchDepartment() {
      this.$axios
        .get(`company/list/departments`)
        .then((res) => {
          if (res.status === 200) {
            this.deparments = res.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res.status === 200) {
            this.branchs = res.data.companyBranches;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    onSelectAllEmployee() {
      this.$nextTick(() => {
        console.log(this.selectAllEmployees);
        if (this.selectAllEmployee.length > 0) {
          this.selectAllEmployee = [];
          this.icon = "mdi-minus-box";
        } else {
          this.selectAllEmployee = this.allEmployee.slice();
          this.icon = "mdi-close-box";
        }
      });
    },
  },
  created() {
    this.fetchDepartment();
    this.fetchBranch();
  },
}
</script>

<style lang="scss" scoped></style>