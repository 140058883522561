<template>
  <div>
    <v-dialog v-model="modalDeduction" width="1200" persistent @close="setModalDeduction(false)"
      transition="scroll-x-transition">
      <v-card class="main-card-delete">
        <v-card-actions class="card-action-delete">
          <v-btn icon depressed @click="setModalDeduction(false)">
            <v-icon class="icon-style">{{ icon }}</v-icon>
          </v-btn>
        </v-card-actions>
        <div class="from-content">
          <v-form ref="form">
            <table style="width: 100%;">
              <tbody class="deduction-table">
                <tr v-for="(item, index) in  deductions " :key="index">
                  <td>
                    <label class="label-input">{{ $t("payrollAddDeduction.deduction_title") }}</label>
                    <v-select outlined dense :items="listDeduction" v-model="item.deductionItem_id" item-text="name"
                      item-value="id" required class="deduction-item"></v-select>
                  </td>
                  <td width="230">
                    <v-radio-group v-model="item.option" style="padding: 0px; margin: 0px; font-size: 12px !important;"
                      class="option-group lfont" row>
                      <v-radio :label="$t('payrollAddDeduction.money')" value="money"></v-radio>
                      <v-radio :label="$t('payrollAddDeduction.percent')" value="percent"></v-radio>
                    </v-radio-group>
                    <div class="deduction-value">
                      <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber" :clearable="true"
                        v-model="item.value" required />
                    </div>

                  </td>
                  <td>
                    <div v-if="item.option == 'percent'">
                      <label class="label-input">{{ $t("payrollAddDeduction.cut_out_deduction") }}</label>
                      <v-checkbox :label="$t('payrollAddDeduction.condition')" class="lfont" style="margin-bottom: -20px;"
                        v-model="item.cut_out_condition"></v-checkbox>
                      <v-btn x-small color="info" dark class="lfont" @click="add_cut_out_deduction(index)">
                        + {{ $t("payrollAddDeduction.deduction_title") }}
                      </v-btn>
                      <ul v-for="( cut_item, i ) in  item.cut_out_deduction_name " :key="i" style="padding: 0px;"
                        class="lfont">
                        <li style="display: flex; justify-content: space-between">
                          <div>{{ i + 1 }} {{ cut_item }}</div>
                          <v-btn icon color="pink" style="height: unset; width:unset"
                            @click="remove_cut_out_deduction(index, i)">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <label class="label-input">{{ $t("payrollAddDeduction.condition") }}</label>
                    <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber" :clearable="true"
                      v-model="item.condition" required class="deduction-item" />
                  </td>
                  <td>
                    <label class="label-input">{{ $t("payrollAddDeduction.employer") }}</label>
                    <v-checkbox v-model="item.employer" color="primary" hide-details class="mt-0 lfont"></v-checkbox>
                  </td>
                  <td v-show="deductions.length > 1">
                    <label class="label-input" style="color: white;">.</label>
                    <span>
                      <div class="removeContact">
                        <i class="fas fa-minus-circle" @click="removeFieldDeduction(item)"></i>
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <v-row>
              <v-col cols="12" class=" mt-5">
                <div class="btn-addFieldContact" @click="addFieldDeduction()">
                  <span><i class="fal fa-plus"></i></span>
                </div>
              </v-col>
              <v-col cols="12" class="mb-5" md="3" lg="3">
                <v-checkbox v-model="adding" :label="$t('payrollAddDeduction.adding')" color="primary" hide-details
                  class="mt-0 lfont"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-btn class="btn-save-change" @click="creteDeduction">
                  {{ $t('payrollAddDeduction.btnSave') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddCutDeduction" max-width="500px">
      <v-card class="lfont">
        <v-card-title>
          <span>ລາຍການຕັດເງີນ</span>
          <v-spacer></v-spacer>

        </v-card-title>
        <div class="pl-5 pr-5">
          <v-select outlined dense :items="listDeductionAdd" v-model="addCutDeduction.value" item-text="name"
            item-value="id" required class="deduction-item"></v-select>
        </div>
        <v-card-actions>
          <v-btn color="primary" text @click="set_cut_out_deduction">
            Add
          </v-btn>
          <v-btn color="red" text @click="dialogAddCutDeduction = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    payroll_emp_id: {},
    payroll_id: '',
    icon: {
      default: "mdi-close",
      type: String,
    },
  },
  data() {
    return {
      dialogAddCutDeduction: false,
      modalDeduction: false,
      listDeduction: [],
      listDeductionAdd: [],
      deductions: [
        {
          deductionItem_id: 1,
          value: "",
          option: "money",
          cut_out_deduction: [],
          cut_out_deduction_name: [],
          condition: "",
          cut_out_condition: false,
          employer: false,
        },
      ],
      adding: false,
      server_errors: {
        adding: "",
        payroll_id: "",
        payrollDeductionItemDetails: {
          detail_id: "",
          cut_out_deduction: [],
          option: "",
          value: "",
          percent: "",
          condition: "",
          cut_out_condition: "",
          employer: "",
        },
      },
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ],
      addCutDeduction: {
        index: '',
        value: ""
      }
    };
  },
  watch: {
    deductions() {
      this.checkDeduction();
    }
  },
  computed: {

  },

  methods: {
    remove_cut_out_deduction(index, indexOfValueToRemove) {
      const updatedCutOutDeduction = [...this.deductions[index].cut_out_deduction];
      const updatedCutOutDeductionName = [...this.deductions[index].cut_out_deduction_name];
      if (indexOfValueToRemove !== -1) {
        updatedCutOutDeduction.splice(indexOfValueToRemove, 1);
        updatedCutOutDeductionName.splice(indexOfValueToRemove, 1);

        // Update the deduction item in the Vue data
        this.$set(this.deductions, index, {
          ...this.deductions[index],
          cut_out_deduction: updatedCutOutDeduction,
        });
        this.$set(this.deductions, index, {
          ...this.deductions[index],
          cut_out_deduction_name: updatedCutOutDeductionName,
        });
      }
    },

    set_cut_out_deduction() {
      this.dialogAddCutDeduction = false;
      let checked = this.deductions[this.addCutDeduction.index].cut_out_deduction.find(item => item === this.addCutDeduction.value);
      if (checked) return;
      let selected_deduction = this.listDeduction.find(item => item.id === this.addCutDeduction.value);
      this.$set(this.deductions, this.addCutDeduction.index, {
        ...this.deductions[this.addCutDeduction.index],
        cut_out_deduction: [...this.deductions[this.addCutDeduction.index].cut_out_deduction, this.addCutDeduction.value],
        cut_out_deduction_name: [...this.deductions[this.addCutDeduction.index].cut_out_deduction_name, selected_deduction.name],
      });
    },

    add_cut_out_deduction(index) {
      this.fetchDeductionsAdd();
      this.dialogAddCutDeduction = true;
      this.addCutDeduction.index = index;
    },

    checkDeduction() {
      let selected_deduction = this.deductions.map(item => item.deductionItem_id);
      this.listDeduction = this.listDeduction.filter(item => !selected_deduction.some(selectedItem => selectedItem === item.id));
    },
    setModalDeduction(isVisible) {
      this.modalDeduction = isVisible;
      this.$emit("change", isVisible);
    },

    resetForm() {
      //this.$refs.form.reset();
      this.deductions = [{
        deductionItem_id: "",
        cut_out_deduction: [],
        cut_out_deduction_name: [],
        value: "",
        option: "money",
        condition: "",
        cut_out_condition: false,
        employer: false,
      }];
    },

    addFieldDeduction() {
      this.deductions.push({
        deductionItem_id: "",
        cut_out_deduction: [],
        cut_out_deduction_name: [],
        value: "",
        option: "money",
        condition: "",
        cut_out_condition: false,
        employer: false,
      });
    },
    removeFieldDeduction(item) {
      let idx = this.deductions.indexOf(item);
      this.deductions.splice(idx, 1);
    },

    fetchDeductions() {
      this.$axios.get(`company/list/payroll/deduction/items`).then((res) => {
        if (res.status === 200) {
          this.listDeduction = res.data.data;
        }
      });
    },

    fetchDeductionsAdd() {
      this.$axios.get(`company/list/payroll/deduction/items/add/${this.payroll_emp_id}`).then((res) => {
        if (res.status === 200) {
          this.listDeductionAdd = res.data.data;
        }
      });
    },

    creteDeduction() {
      this.$emit("save", true);
      const item = {
        payroll_id: this.payroll_id,
        payrollDeductionItemDetails: this.deductions,
        adding: this.adding ? 'all' : 'one'
      };
      const payroll_emp_id = this.payroll_emp_id;
      this.$axios
        .post(
          `company/add/payroll/deduction/item/deatils/${payroll_emp_id}`,
          item
        )
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.setModalDeduction(false);
            this.resetForm();
            this.$emit("success");
          }
        }).catch((error) => {
          this.$emit("save", false);
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  watch: {
    visible(val) {
      this.modalDeduction = val;
    },
  },
  created() {
    this.fetchDeductions();
  },
};
</script>

<style scoped lang="scss">
.deduction-table {
  tr {
    td {
      border: 1px solid rgb(210, 210, 210);
    }
  }
}

.deduction-item {
  margin-top: 20px;
}

table {
  tr {
    td {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.deduction-value {
  display: flex;

  .cut-out-deduction {
    width: 180%;
    padding: 0px 5px;
  }
}

.option-group {
  margin-top: 0px;
  display: flex;

}

.from-content {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 60px;

  .from {
    width: 100%;
    height: auto;
    padding: 20px 40px;
  }
}

.removeContact {
  width: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  i {
    font-size: 1.4rem;
    margin-top: 8px;
    color: #ff3d6a;
    cursor: pointer;
  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4a4a4a;
}

.btn-addFieldContact {
  width: 50px;
  height: 30px;
  background-color: $main-btn-color;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: $font-family;
}
</style>
