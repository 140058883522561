<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    Confirm approve payroll employee
                </v-card-title>

                <v-card-text>
                    <label for="">Type <span class="text-danger">confirm</span> in the
                        box then click enter </label>
                    <span class="text-danger">{{ confirm_fail }}</span>
                    <v-text-field outlined dense v-model="key_confirm" @keyup.enter="confirmProvePayroll"></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="blue darken-1" text @click="confirmProvePayroll">
                        Enter
                    </v-btn>

                    <v-btn color="red darken-1" text @click="$emit('close', false)">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: {
        dialog: "",
        payroll_id: ""
    },
    data() {
        return {
            key_confirm: "",
            confirm_fail: "",
        }
    },
    methods: {
        confirmProvePayroll() {
            if (this.key_confirm == 'confirm') {
                this.approveSlip();
            } else { }
            this.confirm_fail = "Type incorrect !!";
        },
        approveSlip() {
            this.key_confirm = '';
            this.confirm_fail = '';
            this.$emit('save', true);
            this.$emit('close', false);
            this.$axios
                .post(`company/approved/pay/slips/${this.payroll_id}`)
                .then((res) => {
                    if (res.status === 200) {
                        this.$emit('success');
                        setTimeout(() => {
                            this.$notification.OpenNotification_AddItem_OnSuccess(
                                "top-right",
                                "primary",
                                3000
                            );
                        }, 300);
                        this.$router.push({
                            name: "payrolls-item.index",
                        });
                    }
                    this.$emit('save', false);
                }).catch((error) => {
                    this.$emit('save', false);
                });
        }
    },
}
</script>

<style lang="scss" scoped></style>