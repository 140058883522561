<template>
    <div>
        <v-row justify="center">
            <v-dialog
              v-model="dialog_import_payroll"
              persistent
              max-width="600px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Import Data</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="lfont">
                    <v-row>
                      <v-col cols="12" md="7" sm="7">
                        <v-row>
                          <v-col cols="12" md="6" sm="6">
                            <v-checkbox
                              v-model="ot"
                              :label="`ໂອທີ`"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="6" sm="6">
                            <v-checkbox
                              v-model="absence"
                              :label="`ຂາດວຽກ`"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="6" sm="6">
                            <v-checkbox
                              v-model="late"
                              :label="`ມາຊ້າ`"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="6" sm="6">
                            <v-checkbox
                              v-model="early"
                              :label="`ອອກໄວ`"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="6" sm="6">
                            <v-checkbox
                              v-model="sso"
                              :label="`ປະກັນສັງຄົມ`"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="6" sm="6">
                            <v-checkbox
                              v-model="tax"
                              :label="`ອາກອນ`"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="5" sm="5">
                        <div class="choose-image">
                          <div class="show-image">
                            <i class="fas fa-cloud-upload-alt"></i>
                            <input
                              type="file"
                              class="input-file-image"
                              ref="fileInput"
                              @change="PreviewFile"
                            />
                          </div>
                        </div>
                        <span v-if="showFile" class="show-file-name">
                          <span class="text-primary">{{ showFile }}</span></span><br />
                        <v-btn
                          color="blue-grey"
                          class="ma-2 white--text"
                          @click="importData"
                          v-if="showFile">
                          Import
                          <v-icon right dark> mdi-cloud-upload </v-icon>
                        </v-btn>

                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <div class="text-center">
                          <a @click="downloadTemplate">Download template</a>
                        </div>
                        
                      </v-col>
                    </v-row>
                    
                  </v-container>
                  
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeModal()"
                  >
                  Close
                  </v-btn>
                </v-card-actions>
              </v-card>
              <Loading v-if="isLoading" />
            </v-dialog>
          </v-row>
    </div>
</template>

<script>
import Loading from "@/components/Loading";
    export default {
        components: {
          Loading
        },
        props: {
            visible: {
                default: false,
            },
            payroll_id:"",
        },
        data() {
            return {
              ot:false,
              absence:false,
              late:false,
              early:false,
              sso:false,
              tax:false,

              isLoading:false,
              dialog_import_payroll: false,
              importFile:"",
              showFile:""
            };
        },
        watch: {
            visible(val) {
                this.dialog_import_payroll = val;
            },
        },
        methods: {
            closeModal(){
                this.dialog_import_payroll = false;
                this.$emit("change", false);
            },
            PreviewFile: function (event) {
              const input = event.target;
              const file = input.files[0];
              const fileType = file["type"];
              const validImageTypes = [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ];
              if (!validImageTypes.includes(fileType)) {
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  "image must be csv,xlsx"
                );
              } else if (input.files && input.files[0]) {
                this.importFile = input.files[0];
                this.showFile = this.importFile.name;
              }
            },
            clearFileInput() {
              this.$nextTick(() => {
                this.$refs.fileInput.value = '';
              });
            },
            importData(){
              let formData = new FormData();
              formData.append("file", this.importFile);
              formData.append("ot", this.ot);
              formData.append("absence", this.absence);
              formData.append("late", this.late);
              formData.append("early", this.early);
              formData.append("sso", this.sso);
              formData.append("tax", this.tax);
              this.isLoading = true;
              this.$axios
                .post(`company/import/payroll/${this.payroll_id}`, formData)
                .then((res) => {
                  if (res.data.code === 200) {
                    this.ot = false;
                    this.absence = false;
                    this.late = false;
                    this.early = false;
                    this.importFile = null;
                    this.showFile  = null;
                    this.server_errors = [];
                    this.clearFileInput();
                    this.$emit("success");
                    this.closeModal();
                    
                  }
                  this.isLoading = false;
                })
                .catch((error) => {
                  this.clearFileInput();
                  this.isLoading = false;
                  const obj = error.response.data.errors;
                  for (let [key, value] of Object.entries(obj)) {
                    let text = value[0];
                    let cut = "";
                    for (var i = 0; i < text.length; i++) {
                      if (!isNaN(text.charAt(i))) {
                        if (text.charAt(i) != " ") {
                          cut = text.charAt(i);
                          break;
                        }
                      }
                    }
                    this.server_errors[key] = text.replace(`${cut}.`, "");
                  }
                });
            },
            downloadTemplate() {
              this.isLoading = true;
              this.$axios
                .get(`company/export/payroll/template/${this.payroll_id}`, {
                  responseType: "blob",
                })
                .then((res) => {
                  this.isLoading = false;
                  const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                  const fileLink = document.createElement("a");
                  fileLink.href = fileUrl;
                  fileLink.setAttribute("download", "import-payroll.xlsx");
                  document.body.appendChild(fileLink);
                  fileLink.click();
                  document.body.removeChild(fileLink);
                })
                .catch((error) => {
                  this.isLoading = false;
                });
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>